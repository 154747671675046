// Import general.ts
import { WebcimesTooltip, WebcimesModal, WebcimesSelect,  axios, _t } from "@ts/general.ts";

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// Export WebcimesTooltip, WebcimesModal, WebcimesSelect, axios, _t
export { WebcimesTooltip, WebcimesModal, WebcimesSelect, axios, _t };

// Import fo-top.ts
import "@ts/components/nav/fo-top.ts";

// Import fo-left.ts
import "@ts/components/nav/fo-left.ts";

// css
import "@css/fo.css";

// Wait for dom content loaded
document.addEventListener("DOMContentLoaded", function() {
    // Gsap scroll
	ScrollTrigger.config({limitCallbacks: true});
	gsap.set(".gsapScroll", {opacity: 0, y: 40});
	ScrollTrigger.batch(".gsapScroll",
	{
		interval: 0.10,
		//batchMax: 3,
		onEnter: (el) => gsap.to(el, {opacity: 1, y: 0, stagger: 0.10, overwrite: true, duration: 1}),
		// onLeave: (el) => gsap.set(el, {opacity: 0, y: 40, overwrite: true}),
		onEnterBack: (el) => gsap.to(el, {opacity: 1, y: 0, stagger: 0.10, overwrite: true, duration: 1}),
		// onLeaveBack: (el) => gsap.set(el, {opacity: 0, y: 40, overwrite: true}),
	});

    // Gsap show on nav-left
    document.querySelector(".nav-top__toggle")?.addEventListener("click", function() {
        gsap.fromTo(".nav-left .gsapShow", {opacity: 0, y: 40}, {opacity: 1, y: 0, stagger: 0.10, overwrite: true, duration: 1});
    });
});